import { Link, usePage } from '@inertiajs/react';
import NavLink from '@/Components/NavLink';
import ApplicationLogo from '@/Components/ApplicationLogo';
import Dropdown from '@/Components/Dropdown';
import Notifications from '@/Components/Notifications';
import Avatar from '@/Components/Avatar';
import Messages from '@/Components/Messages';

import { useEffect, useRef } from 'react';
import { capitalize } from 'lodash';

export default function Header({}) {
	const { auth, constants } = usePage().props;
	const dropdownRef = useRef();

	const marketplaceCategories = Object.keys(constants.searchFilters);

	useEffect(() => {
		dropdownRef.current.removeAttribute('open');
	}, [auth]);

	return (
		<div className="header sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-95 bg-base-100 transition-all duration-100">
			<div className="w-full navbar">
				<div className="navbar-start">
					<Link href="/" className="btn btn-ghost normal-case text-xl px-2">
						<ApplicationLogo />
					</Link>
				</div>
				<div className="navbar-center hidden lg:block">
					<ul className="menu menu-horizontal px-1 gap-2">
						<li>
							<NavLink
								href={route('championships')}
								active={route().current('championships' + '*')}
							>
								Championships
							</NavLink>
						</li>
						<li>
							<NavLink
								href={route('events')}
								active={route().current('events' + '*')}
							>
								Events
							</NavLink>
						</li>
						<li>
							<NavLink
								href={route('communities')}
								active={route().current('communities' + '*')}
							>
								Communities
							</NavLink>
						</li>
						<li>
							<NavLink
								href={route('time.trials')}
								active={route().current('time.trials' + '*')}
							>
								Time Trials
							</NavLink>
						</li>
						<li>
							<NavLink href={route('teams')} active={route().current('teams' + '*')}>
								Teams
							</NavLink>
						</li>
						<li>
							<NavLink
								href={route('blogs.index')}
								active={route().current('blogs' + '*')}
							>
								Posts
							</NavLink>
						</li>
						<li>
							<details ref={dropdownRef}>
								<summary className="uppercase font-bold focus:bg-transparent">
									Marketplace
								</summary>
								<ul className="p-2 bg-base-300">
									<li>
										<NavLink href={route('marketplace')} active={route().current('marketplace')} className="!focus:text-base">
											Home
										</NavLink>
									</li>
									<li></li>
									{marketplaceCategories?.map((category, i) => (
										<li key={i}>
											<NavLink
												href={route('marketplace.listings', category)}
												active={route().current(
													'marketplace.listings',
													category
												)}
												className="!focus:text-base"
											>
												{capitalize(category)}
											</NavLink>
										</li>
									))}
								</ul>
							</details>
						</li>
					</ul>
				</div>
				<div className="navbar-end gap-2">
					{auth.user ? (
						<>
							<Messages />
							<Notifications />

							<Dropdown>
								<Dropdown.Trigger classes="btn btn-circle align-top">
									<Avatar width="w-100" url={auth.user.avatar} />
								</Dropdown.Trigger>
								<Dropdown.Content width="w-72">
									<Dropdown.Link className="uppercase font-bold" href={route('profile.show', auth.user.username)}>
										<span className="material-icons-outlined">
											account_circle
										</span>
										My Profile
									</Dropdown.Link>
									<li></li>
									<Dropdown.Link
										href={route('profile.championships', auth.user.username)}
										className="uppercase font-bold"
									>
										<span className="material-icons-outlined">
											emoji_events
										</span>
										My Championships
									</Dropdown.Link>
									<Dropdown.Link
										href={route('profile.events', auth.user.username)}
										className="uppercase font-bold"
									>
										<span className="material-icons-outlined">event</span>
										My upcoming Events
									</Dropdown.Link>
									<Dropdown.Link
										href={route('profile.results', auth.user.username)}
										className="uppercase font-bold"
									>
										<span className="material-icons-outlined">
											sports_score
										</span>
										My results
									</Dropdown.Link>
									<li></li>
									<Dropdown.Link
                                        href={route('user.marketplace')}
                                        className="uppercase font-bold"
                                    >
										<span className="material-icons-outlined">
											shopping_cart
										</span>
										Marketplace
									</Dropdown.Link>
									<li></li>
									<Dropdown.Link
										href={route('user.account.edit')}
										className="uppercase font-bold"
									>
										<span className="material-icons-outlined">settings</span>
										Account settings
									</Dropdown.Link>
									<li></li>
									<Dropdown.Link
										href={route('logout')}
										method="post"
										as="button"
										className="uppercase font-bold"
									>
										<span className="material-icons-outlined">logout</span>
										Logout
									</Dropdown.Link>
								</Dropdown.Content>
							</Dropdown>
						</>
					) : (
						<>
							<ul className="menu menu-horizontal px-1">
								<li>
									<Link href={route('login')}>Log in</Link>
								</li>
								<li className="hidden md:block">
									<Link href={route('register')}>Create an account</Link>
								</li>
							</ul>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
