import { useEffect, useState } from 'react';
import { router, usePage, Link } from '@inertiajs/react';
import Dropdown from '@/Components/Dropdown';
import Indicator from './Indicator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export default function Notifications({}) {
	dayjs.extend(relativeTime);

	const { auth } = usePage().props;
	const [items, setItems] = useState([]);
	const [unreadCount, setUnreadCount] = useState(
		auth.user.unread_notifications.length
	);

	useEffect(() => {
		Echo.private(
			`App.Models.User.${auth.user.id}`
		).notification((notification) => {
			setUnreadCount((prev) => prev + 1);
		});
	}, []);

	useEffect(() => {
		setItems(auth.user.unread_notifications);
		setUnreadCount(
			auth.user.unread_notifications.length
		);
	}, [auth]);

	const fetchNotifications = () => {
		router.reload({ only: ['auth'] });
	};

	const markAllRead = () => {
		router.post(
			route('notifications.markallread'),
			{},
			{
				preserveState: true,
				preserveScroll: true
			}
		);
	};

	return (
		<Indicator value={unreadCount}>
			<Link
				href={route('notifications')}
				className="btn btn-circle md:hidden"
			>
				<div className="indicator">
					<span className="material-icons-outlined">
						notifications
					</span>
				</div>
			</Link>
			<div className="hidden md:block">
				<Dropdown toggleAction={fetchNotifications}>
					<Dropdown.Trigger classes="btn btn-circle align-top">
						<div className="indicator">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-7 w-7"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
								/>
							</svg>
						</div>
					</Dropdown.Trigger>
					<Dropdown.Content width="w-96">
						<div className="px-4 py-2">
							<div className="flex justify-between items-center">
								<h2 className="font-bold text-lg">
									Notifications
								</h2>
								<Dropdown>
									<Dropdown.Trigger classes="btn btn-sm btn-circle">
										<span className="material-icons-outlined">
											more_horiz
										</span>
									</Dropdown.Trigger>
									<Dropdown.Content width="w-60">
										<Dropdown.Link
											onClick={
												markAllRead
											}
										>
											<span className="material-icons-outlined">
												done
											</span>
											Mark all as read
										</Dropdown.Link>
										<Dropdown.Link
											href={route(
												'notifications'
											)}
										>
											<span className="material-icons-outlined">
												notifications
											</span>
											Open
											notifications
										</Dropdown.Link>
									</Dropdown.Content>
								</Dropdown>
							</div>
						</div>
						<div className="max-h-[60vh] min-h-[50px] overflow-y-auto">
							{items.length ? (
								items.map((item, index) => (
									<Dropdown.Link
										key={`notification-${item.id}`}
										href={route(
											'notifications.action',
											item.id
										)}
									>
										<div>
											{
												item.data
													.message
											}
											<br />
											<small>
												{dayjs(
													item.created_at
												).fromNow()}
											</small>
										</div>
									</Dropdown.Link>
								))
							) : (
								<div className="px-4 py-2 text-center">
									You have no unread
									notifications
								</div>
							)}
						</div>
					</Dropdown.Content>
				</Dropdown>
			</div>
		</Indicator>
	);
}
